var $ = require("jquery"),
	imagesloaded = require("imagesloaded"),
	app = require("faction-client-site").App,
	HomeView = require("./views/homeView"),
	TeamView = require("./views/teamView"),
	TeamBioView = require("./views/teamBioView"),
	ClientView = require("./views/clientView"),
	ClientDetailView = require("./views/clientDetailView"),
	DisclosureView = require("./views/disclosureView"),
	PrivacyPolicyView = require("./views/privacyPolicyView"),
	DisclaimerView = require("./views/disclaimerView"),
	error404View = require("./views/error_404"),
	Router = require("faction-client-site").Router,
	NavView = require("./views/navView"),
	FooterView = require("./views/footerView");

module.exports = function () {
	$(document).ready(function(){

		var nav = new NavView(),
			footer = new FooterView(),
			showLogs = false, // set to false to hide all loggging from the app
			direction = null; // used to help determine the direction pages animate in and out when selecting prev or next

			logSettings = {
				debugAll: false,
				groups: {
					'public':true,
					'home':true
				}
			};

		// Listen for UA string parse
		app.channel('browser').on('ua', function (browser) {
				console.log(browser.browser);
	        var classNames = [];

	        switch (browser.browser) {
							case 'Chrome':
									if (navigator.userAgent.indexOf('Windows') !== -1) {
										classNames.push('is_win_chrome');
									}
							break;
	            case 'Firefox':
	                classNames.push('is_ff');
	            break;

	            case 'Safari':
	                switch(browser.major) {
	                    case '6':
	                        classNames.push('is_safari6');
	                    break;
	                }
	            break;

	            case 'Mobile Safari':
	                switch(browser.major) {
	                    case '6':
	                        classNames.push('is_ios6');
	                    break;
	                }
	            break;

	            case 'IE':
	                switch (browser.major) {
	                	case '11':
	                        classNames.push('is_ie11');
	                    break;

	                    case '10':
	                        classNames.push('is_ie10');
	                    break;

	                    case '9':
	                        classNames.push('is_ie9');
	                    break;
	                }
	        }

			$('html').addClass(classNames.join(' '));
		});

		// capture our window events here, and broadcast them on the browser channel
		window.addEventListener('scroll', function (evt) {
			app.channel('browser').trigger('scroll', evt);
		});

		window.addEventListener('resize', function (evt) {
			app.channel('browser').trigger('resize', evt);
		});

        window.addEventListener('mousemove', function (evt) {
        	app.channel('browser').trigger('mousemove', evt);
        });

        window.addEventListener('deviceorientation', function (evt) {
        	app.channel('browser').trigger('deviceTilt', evt);
        });

        app.channel('browser').on('scroll:top', function() {
        	app.channel('logs').trigger('console', 'scroll top!', 'public');
        	window.scrollTo(0,0);
        });

        app.channel('browser').on('update:nav', function(navClass) {
        	$('.'+navClass).addClass('current');
        });

        app.channel('browser').on('toggle:footer', function(action) {
        	if(action === 'hide') {
        		$('#footer').addClass('hidden');
        	} else {
        		$('#footer').removeClass('hidden');
        	}

        });

				app.channel('router').on('change', function(url, paths) {
					if(window.ga) {
						window.ga('send', 'pageview', {
							'page': url
						});
					}
				});

        app.channel('loading').on('start', function(){
        	app.channel('logs').trigger('console', 'start loading!', 'public');
        });

        // All images on page loaded
        // Need to pass in a class that is on all elements with background images
        // Need to pass in an element to remove a class of hidden from to show page content
        app.channel('loading').on('load:images', function(bgImgClass,view) {
			var images = $('img');
			$(bgImgClass).each(function(){
				var el = $(this),
					image = el.css('background-image').match(/url\((['"])?(.*?)\1\)/);

				if(image) {
					images = images.add($('<img>').attr('src', image.pop()));
				}
			});
			imagesloaded(images, function(){
				view.imgsLoaded();
			});
        });

        // return if the prev or next button was selected
        app.channel('direction').reply('navdirection', function() {
        	return direction;
        });

        // set if the prev or next button was selected
        app.channel('direction').on('update:direction', function(dir) {

        	direction = dir;
        });

        // Console logging channel
        app.channel('logs').on('console', function(message, group){
        	// if debugAll is set to true
        	// show all logs
        	// else if the group is set to show
        	// show logs from that group

        	return logSettings.debugAll ? console.log(group," - ",message) : logSettings.groups[group] ? console.log(group," - ",message) : null;

        });

		app.run({
			router: new Router(),
			apiUrl: '/'
		});
	});
}();
