var $ = require("jquery");
var _ = require("lodash");
var Backbone = require("backbone");
var imagesloaded = require("imagesloaded");
// var Modernizr = require("../vendor/modernizr.min");
var app = require("faction-client-site").App;
var View = require('faction-client-site').View;

var win = window,
    $win = $(win),
    isTouch = Modernizr.touch;

module.exports = View.create('template:client_detail', {

    _el: '#client',
    modules: [],

    events: {
        'click #internal_nav a': 'navigate'
    },

    init: function( ) {
        this.app.channel('browser').trigger('scroll:top'); // scroll to the top
        this.app.channel('browser').on('update:internalNav', this.updateNav, this);
    },

    afterRender: function () {
        $('#nav').removeClass('out'); // Remove the out class from the nav (animates the nav off the screen)
        $(this._el).removeClass('hidden');
        this.app.channel('browser').trigger('toggle:footer', 'show');
    },

    updateNav:function(data) {
        var $prev = $(this._el).find('.prev'),
            $next = $(this._el).find('.next'),
            $internalNav = $(this._el).find('#internal_nav');

        $internalNav.addClass('hidden');
        setTimeout(function(){
            $prev.attr('href',data.prevSlug);
            $prev.find('.link .copy').text(data.prevName);
            $prev.find('.link .hover').attr('data-hover', data.prevName);

            $next.attr('href',data.nextSlug);
            $next.find('.link .copy').text(data.nextName);
            $next.find('.link .hover').attr('data-hover', data.nextName);

            $internalNav.removeClass('hidden');
        },300);

    },

    navigate: function(evt) {
        var dir = evt.currentTarget.className;
        this.navDirection = dir;
        this.app.channel('direction').trigger('update:direction', dir); // set the direction selected to direction channel

        if(window.ga) {
          window.ga('send', 'pageview', {
            'page': evt.currentTarget.getAttribute('href')
          });
        }

        app.router.navigateSubView(evt.currentTarget.getAttribute('href'), $(evt.currentTarget).attr('data-sub-view'));

        return false;
    },

    renderOut: function (cb) {
        $(this._el).addClass('hidden');
        this.app.channel('browser').trigger('toggle:footer', 'hide');
        setTimeout(function () {
            cb();
         },500);

    },

    destroy: function () {
        this.app.channel('browser').off('scroll', this.onScroll, this); // remove scroll listener
        this.modules = _.invoke(this.modules, 'destroy') ? [] : [];
        this.undelegateEvents();
        this.stopListening();
        this.$el.off();
    },
});
