var $ = require("jquery");
var _ = require("lodash");
var Backbone = require("backbone");
var app = require("faction-client-site").App;
var View = require('faction-client-site').View;

var win = window,
    $win = $(win),
    isTouch = Modernizr.touch;

module.exports = View.create('template:client_detail:client', {

    _el: '#client_content',
    outlet: 'sub_client',

    events: {
        'click a': 'navigateSubView'
    },

    init: function( ) {
        var internalNav = {
            prevSlug: $(this._el).attr('data-prev'),
            prevName: $(this._el).attr('data-prev-name'),
            nextSlug: $(this._el).attr('data-next'),
            nextName: $(this._el).attr('data-next-name'),
        };
        this.app.channel('browser').trigger('scroll:top'); // scroll to the top
        this.app.channel('browser').trigger('update:internalNav', internalNav);
        this.navDirection = app.channel('direction').request('navdirection'); // get which of the prev button, next button, or neither were selected
        this.navDirection = this.navDirection === 'prev' ? 'next'
            : this.navDirection === 'next' ? 'prev' : null; // swap value of direction to ensure the page animates in, in the correct direction
        $('#nav .current').removeClass('current');
        $('#nav a[href="/'+Backbone.history.fragment+'"]').addClass('current');
    },

    afterRender: function () {

        var self = this;
        this.setHeight();
        $(this._el).parent().addClass('no-trans');
        $(this._el).parent().addClass(this.navDirection); // set direction class to page (so it can animate in, in the correct direction)
        //$('#nav').removeClass('out'); // Remove the out class from the nav (animates the nav off the screen)
        setTimeout(function(){
            $(self._el).parent().removeClass('no-trans');
            $(self._el).parent().removeClass(self.navDirection); // animates the current page into view
            $(self._el).removeClass('hidden');
            $(self._el).parent().css({'max-height':'', 'height': ''});
            $(self._el).parent().removeClass('animate');
            self.app.channel('browser').trigger('toggle:footer', 'show');
        }, 300);
    },

    setHeight: function() {
        var self = this,
            prevHeight = $(this._el).parent().height(),
            height = $(this._el).outerHeight();

        if(height > prevHeight) {
            $(this._el).parent().css({'height': height});
            setTimeout(function(){
                $(self._el).parent().css({'max-height': height});
            },100);
        } else {
            $(this._el).parent().css({'max-height': height});
            setTimeout(function(){
                $(self._el).parent().css({'height': height});
            },300);
        }
    },

    renderOut: function (cb) {
        var self = this;
        this.setHeight();
        $(this._el).addClass('hidden');
        this.navDirection = app.channel('direction').request('navdirection');
        $(this._el).parent().addClass(this.navDirection + ' animate');
        $('html, body').animate({'scrollTop':0},300);
        this.app.channel('browser').trigger('toggle:footer', 'hide');
        setTimeout(function () {
            $(self._el).parent().removeClass(self.navDirection);
            cb();
         },300);

    },

    destroy: function () {
        this.undelegateEvents();
        this.stopListening();
        this.$el.off();
    },
});
