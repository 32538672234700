var $ = require("jquery");
var _ = require("lodash");
var Backbone = require("backbone");
// var Modernizr = require("../vendor/modernizr.min");
var app = require("faction-client-site").App;
var View = require('faction-client-site').View;

var win = window,
    $win = $(win),
    isTouch = Modernizr.touch;

module.exports = View.create('template:privacy_policy', {

    _el: '#privacy-policy',

    events: {
        'click a:not([target="_blank"]': 'navigate'
    },

    init: function( ) {
        // Defaults
        this.modules = [];
        this.app.channel('browser').trigger('scroll:top'); // scroll to the top
        $('#nav .current').removeClass('current');
    },

    afterRender: function () {
        $('#nav').removeClass('out'); // Remove the out class from the nav (animates the nav off the screen)
        $(this._el).removeClass('hidden');
        this.app.channel('browser').trigger('toggle:footer', 'show');
    },


    renderOut: function (cb) {
        $(this._el).addClass('hidden');
        this.app.channel('browser').trigger('toggle:footer', 'hide');
        setTimeout(function () {
            cb();
         },500);

    },

    destroy: function () {
        this.app.channel('browser').off('scroll', this.onScroll, this); // remove scroll listener
        this.modules = _.invoke(this.modules, 'destroy') ? [] : [];
        this.undelegateEvents();
        this.stopListening();
        this.$el.off();
    },
});
