/**
 ** @module Animate Across
 */

var app = require('faction-client-site').App,
    TweenLite = require("../../vendor/TweenLite"),
    cssTL = require("../../vendor/plugins/CSSPlugin.min"),
    easing = require("../../vendor/easing/EasePack"),
	$ = require('jquery'),
	_ = require('lodash')

window.App = app;

/**
 * Sets up element to animate across the screen.
 * @class
 * @param {*} el - Element that is to animate
 */

function AnimateAcross (el) {
	app.channel('browser').on('resize', this.onResize, this); // listen to resize on the browser channel
	//app.channel('browser').on('deviceTilt', this.perspective, this); // listen for when a touch device is tilted

	//Defaults
	this.el = el;
	this.$el = $(el);
	this.$win = $(window);
	//this.direction = Math.round(Math.random()) === 1 ? 'left' : 'right'; // randomly generate starting direction of element
	this.tween = null;
	this.size = Math.round(Math.random()) === 1 ? '' : 'large';
	this.pos = Math.round(Math.random()) === 1 ? '' : 'alt_pos';
	this.firstRun = true;
	this.onResize();
	this.animate();

}


/**
 * Animates the element accross the screen.
 * @memberOf Animate Across
 */

AnimateAcross.prototype.animate = function() {
	this.size = Math.round(Math.random()) === 1 ? '' : 'large'; // Reset the large class
	this.pos = Math.round(Math.random()) === 1 ? '' : 'alt_pos';
	this.direction = Math.round(Math.random()) === 1 ? 'left' : 'right'; // randomly generate starting direction of element

	if(!this.firstRun) {
		$(this.el).css(this.direction,'-100%'); // Reset element position
	}

	$(this.el).addClass(this.size);
	$(this.el).addClass(this.pos);
	var self = this,
		duration = this.ranNum(),
		options = { // Set options for TweenLite animation
			ease:Linear.ease,
			onComplete: self.animate,
			onCompleteScope:self
		};

	options[this.direction] = '100%'; // Set the randomly generate starting direction
	
	this.tween = TweenLite.to(this.el, duration, options); // animate element
	this.firstRun = false;
}

/**
 * Return a random number between two numbers, which are dependent on the size of the screen
 * @memberOf Animate Across
 */

AnimateAcross.prototype.ranNum = function() {
	var min = this.ww < 680 ? 100 : 150,
		max = this.ww < 680 ? 130 : 180;
	return Math.floor(Math.random() * (max - min) + min);
}

/**
 * On resize set the new window width to the ww variable.
 * @memberOf Animate Across
 */

AnimateAcross.prototype.onResize = function() {
	this.ww = this.$win.width();
}

/**
 * Remove resize listener and null out all variables.
 * @memberOf Animate Across
 */

AnimateAcross.prototype.destroy = function() {
	this.el = null;
	this.$win = null;
	this.ww = null;
	this.tween.kill();
	this.direction = null;
	app.channel('browser').off('resize', this.onResize, this); // remove resize listener
	app.channel('browser').off('deviceTilt', this.perspective, this); // remove deviceTilt listener
}

module.exports = AnimateAcross;
