var $ = require("jquery");
var _ = require("lodash");
var Backbone = require("backbone");
var imagesloaded = require("imagesloaded");
// var Modernizr = require("../vendor/modernizr.min");
var app = require("faction-client-site").App;
var View = require('faction-client-site').View;
var LookAround = require('./module/look.around');
var SectionAnimationUp = require("./module/section.anim.up");

var win = window,
    $win = $(win),
    isTouch = Modernizr.touch;

module.exports = View.create('template:home', {

    _el: '#home',
    modules: [],


    events: {
        'click a': 'navigate',
        'click #hero h1': 'scrollTo',
        'click #down_arrow': 'scrollTo'
    },

    init: function( ) {
        this.app.channel('browser').on('scroll', this.onScroll, this); // listen to scrolling on the browser channel
        this.app.channel('browser').on('resize', this.onResize, this); // listen for window resizing
        this.app.channel('browser').trigger('scroll:top'); // scroll to the top
        this.app.channel('loading').trigger('load:images', '#buildings', this); // load ze images - be sure to have function "imgsLoaded" for the callback
        this.app.channel('logs').trigger('console', this, 'home');
        $('#nav .current').removeClass('current');
        $('#logo').addClass('current');
    },

    afterRender: function () {
        var self = this;
        var isMobileTouch = $('html').hasClass('is_win_chrome') ? false : isTouch;
        this.logo = this.el.querySelector('#hero h1'); // get logo
        this.clouds = this.el.querySelectorAll('.cloud'); // get all clouds

        $(this.clouds).each(function(i){ // create new instances of the Cloud module for all clouds on the page

            self.modules.push(new LookAround({
                el:this,
                topOffset:true,
                reducer: i+1,
                device: isMobileTouch ? 'mobile' : 'desktop'
            }));

        });

        this.modules.push(new LookAround({
            el:this.logo,
            topOffset:false,
            reducer: 1.5,
            device: isMobileTouch ? 'mobile' : 'desktop'
        }));

        this.modules.push(new LookAround({
            el:this.el.querySelector("#buildings"),
            topOffset:false,
            reducer: 2.5,
            device: isMobileTouch ? 'mobile' : 'desktop'
        }));
        if(!isTouch || $('html').hasClass('is_win_chrome')) {
            $(this._el).find('.offset_section').each(function(){
                self.modules.push(new SectionAnimationUp(this));
            });
        }


        this.onResize();
    },

    imgsLoaded: function() {
        $(this.el).removeClass('hidden');
        $('#nav').removeClass('out'); // Remove the out class from the nav (animates the nav off the screen)
        this.app.channel('browser').trigger('toggle:footer', 'show');
    },

    scrollTo: function() {
        $('html,body').animate({ scrollTop: $("#hero").height()},'slow');
    },

    onScroll: function() {
        if(!isTouch || $('html').hasClass('is_win_chrome')) {
            var winScrollTop = $win.scrollTop(),
                logoOffsetScroll = Math.floor(($win.scrollTop() / 4)+26);
                cloudsOffsetScroll = Math.floor(($win.scrollTop() / 9));
            // create parallax effect when scrolling down the page on the logo and clouds
            $(this.logo).css('margin-top',-logoOffsetScroll);
            $(this.clouds).css('margin-top',-cloudsOffsetScroll);
        }

    },

    onResize: function() {
        var wh = $win.height();
        this.el.querySelector('#hero').style.height = wh + "px";
    },

    renderOut: function (cb) {
        $(this.el).addClass('hidden');
        this.app.channel('browser').trigger('toggle:footer', 'hide');
        // setTimeout(function () {
        //     $('#loader').removeClass('hidden');
        // },300);

        setTimeout(function () {
            cb();
         },500);

    },

    destroy: function () {
        this.app.channel('browser').off('scroll', this.onScroll, this); // remove scroll listener
        this.app.channel('browser').off('resize', this.onResize, this);// remove resize listener
        this.modules = _.invoke(this.modules, 'destroy') ? [] : [];
        this.undelegateEvents();
        this.stopListening();
        this.$el.off();
    },
});
