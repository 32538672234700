/**
 ** @module Sprite Menu
 */

var app = require('faction-client-site').App,
	$ = require('jquery'),
	_ = require('lodash'),
    rAF = require('../../vendor/polyfill/rAF.pollyfill'),
    isTouch = Modernizr.touch,
    isIE9 = $('html').hasClass('is_ie9'),
    requestAnimationFrame = window.requestAnimationFrame ||
                            window.mozRequestAnimationFrame ||
                            window.webkitRequestAnimationFrame ||
                            window.msRequestAnimationFrame;

	window.App = app;

/**
 * Initializes Animations for the Sections.
 * @class
 * @param {*} el - Section Container
 */

function SpriteMenu (el) {
    this.$el = $(el);
    this.frameCount = isTouch ? 37 : 0;
    this.frameRate = isTouch ? 0 : 1;
    this.frameRateCount = 0;
    this.stackFrames = isTouch ? 24 : 14; //7
    this.plusFrames = 25;
    this.xToBurgerFrames = 37;
    this.animating = false;
    this.dir = "forward";
    this.navOpen = false;
}

/**
 * Move Sprite animation forward.
 * @memberOf SpriteMenu
 */

SpriteMenu.prototype.direction = function(direction) {
    if(this.dir !== "x-burger") {
        this.dir = direction;
    }

    if(!this.animating) {
        this.frameCounter();
    }
}

SpriteMenu.prototype.frameCounter = function() {
    var frames = this.navOpen ? this.plusFrames : this.stackFrames;
    if(!isTouch) {
        if(this.dir === "forward" && this.frameCount < frames) {
            this.animating = true;
            if(this.frameRateCount > this.frameRate) {
                this.frameRateCount = 0;
                this.updateFrame();
                this.frameCount ++;
            }

            this.frameRateCount ++;

            requestAnimationFrame(this.frameCounter.bind(this));

        } else if(this.dir === "backward" && this.frameCount > -1 ) {
            this.animating = true;
            if(this.frameRateCount > this.frameRate) {
                this.frameRateCount = 0;
                this.frameCount --;
                this.updateFrame();

            }

            this.frameRateCount ++;

            requestAnimationFrame(this.frameCounter.bind(this));
        } else if(this.dir === "x-burger" && this.frameCount < this.xToBurgerFrames){
            this.animating = true;
            if(this.frameRateCount > this.frameRate) {
                this.frameRateCount = 0;
                this.updateFrame();
                this.frameCount ++;
            }

            this.frameRateCount ++;

            requestAnimationFrame(this.frameCounter.bind(this));

        }  else {
            this.animating = false;
            if(this.dir === "x-burger") {
                this.frameCount = 0;
                this.dir = "forward";
            }
        }

    } else {
        if(this.dir === "forward" && this.frameCount > this.stackFrames) {
            
            this.animating = true;
            if(this.frameRateCount > this.frameRate) {
                this.frameRateCount = 0;
                this.frameCount --;
                this.updateFrame();

            }

            this.frameRateCount ++;

            requestAnimationFrame(this.frameCounter.bind(this));
        } else if(this.dir === "backward" && this.frameCount < this.xToBurgerFrames) {

            this.animating = true;
            if(this.frameRateCount > this.frameRate) {
                this.frameRateCount = 0;
                this.updateFrame();
                this.frameCount ++;
            }

            this.frameRateCount ++;

            requestAnimationFrame(this.frameCounter.bind(this));

        } else {
            this.animating = false;
        }
    }

}

SpriteMenu.prototype.updateFrame = function() {
    this.$el.css("background-position","-" + this.frameCount * 51 + "px 0px");
}

SpriteMenu.prototype.navigationOpen = function(bool) {
    this.navOpen = bool;
}


/**
 * Remove listeners and null out all variables.
 * @memberOf SpriteMenu
 */

SpriteMenu.prototype.destroy = function() {
	this.$el = null;
    this.animated = false;
    this.offsetVal = null;
    this.distanceLeft = null;
}

module.exports = SpriteMenu;
