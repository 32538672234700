var $ = require("jquery"),
    _ = require("lodash"),
    Backbone = require("backbone"),
    app = require("faction-client-site").App,
    View = require('faction-client-site').View;


module.exports = View.extend({
    _el: '#footer',
    el: '#footer',
    clickDelay: 300,

    scrollPos: 0,

    events: {
        'click a:not([target="_blank"], .email, .telephone, #left, #right)': 'navigate',
        'click #left': 'openNavLeft',
        'click #right': 'openNavRight'
    },

    initialize: function (opts) {
        this.render();
    },

    render: function(data) {

    },

    openNavLeft: function (e) {
      e.preventDefault();
      this.app.channel('browser').trigger('open:navLeft');
    },

    openNavRight: function (e) {
      e.preventDefault();
      this.app.channel('browser').trigger('open:navRight');
    }


});
