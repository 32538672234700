/**
 ** @module Look Around
 */

var app = require('faction-client-site').App,
    TweenLite = require("../../vendor/TweenLite"),
    cssTL = require("../../vendor/plugins/CSSPlugin.min"),
    easing = require("../../vendor/easing/EasePack"),
	$ = require('jquery'),
	_ = require('lodash'),
	AnimateAcross = require('./anim.across');

window.App = app;

/**
 * Creates a new element which responds to mouse and device tilt to give the illusion of a 3D environment.
 * @class
 * @param {*} el - Element that is to animate
 */

function LookAround (options) {
	var self = this,
	isTouch = Modernizr.touch;
	// Options
	this.$el = $(options.el);
	this.topOffset = options.topOffset;
	this.reducer = options.reducer === "random" ? Math.floor(Math.random() * (5 - 1) + 1) : options.reducer;
	this.device = options.device;
	// Defaults
	this.modules = [];
	this.x = -1;
	this.y = -1;
	this.orientation = this.device === "mobile" ? "portait" : "landscape";
	this.win = window;
	this.$win = $(this.win);
	this.ww = this.$win.width();
	this.wh = this.$win.height();

    this.lastMoveX = 0;
    this.lastMoveY = 0;
    this.lastAccelX = 0;
    this.lastAccelRawX = 0;
    this.lastAccelY = 0;
    this.lastAccelA = 0;
    this.ticks = 0;

    if (isTouch && !$('html').hasClass('is_win_chrome')) {
      app.channel('browser').on('deviceTilt', this.onDeviceTilt, this); // listen for when a touch device is tilted
    } else {
      app.channel('browser').on('mousemove', this.onMouseMove, this); // listen for the mouse moving
    }



	if(this.topOffset) {
		this.modules.push(new AnimateAcross(options.el));
	}

}




LookAround.prototype.normalizeAccelVal = function(val, ticks, reducer, min, max) {

    var projectedVal = val/ticks;

    projectedVal = projectedVal < max && projectedVal > min ? projectedVal : ((projectedVal > max) ? max : min);


    return projectedVal;
};

/**
 * Moves the element slightly when the device is tilted.
 * @param {object} e - event
 * @memberOf Look Around
 */

LookAround.prototype.onDeviceTilt = function(e) {
        var x = e.gamma,
        y = e.beta,
        a = e.alpha,
        xRaw = x,
        yRaw = y,
        diff = 0,
        diffThresholdX = 250,
        threshold = 0.1;

        x = this.normalizeAccelVal((xRaw)/2, 1, 0, -40, 40);
        y = this.normalizeAccelVal((yRaw)/2, 1, 0, -45, 45);

        diff = (this.lastAccelRawX - xRaw);

        if(diff < 0) {
            diff = diff * -1;
        }

        if(diff > diffThresholdX) {
            x = this.normalizeAccelVal((xRaw *-1)/2, 1, 0, -40, 40);
            return;
        };

        if(diff < diffThresholdX) {

            this.lastAccelX = x;
            this.lastAccelY = y;
            this.lastAccelRawX = xRaw;
            this.lastAccelRawY = yRaw;

            this.updatePos(x, y);
            //this.$el.css('transform', 'translateX('+x/this.reducer+'px) translateY('+y/this.reducer+'px)');
        }
}

/**
 * Moves the element slightly when the mouse is moved around the window.
 * @param {object} e - event
 * @memberOf Look Around
 */

LookAround.prototype.onMouseMove = function(e) {
    e = e || window.event; // IE
    var horizontalCenter = this.ww / 2,
        verticalCenter = this.wh / 2,
        mouseX = e.clientX,
        mouseY = e.clientY;

    mouseX = ((mouseX - horizontalCenter) / horizontalCenter)*25;

    mouseY = ((mouseY - verticalCenter) / verticalCenter)*25;

    this.updatePos(-mouseY,-mouseX);
}

/**
 * Update the position of the element based on X and Y coordinates.
 * param {num} x - new X coordinate
 * param {num} y - new Y coordinate
 * @memberOf Look Around
 */

LookAround.prototype.updatePos = function(x,y) {
	var xVal = this.orientation === "portait" ? x : y,
		yVal = this.orientation === "portait" ? y : x;
	this.$el.css('transform', 'translateX('+xVal/this.reducer+'px) translateY('+yVal/this.reducer+'px)');
}

LookAround.prototype.onResize = function() {
	this.ww = this.$win.width();
	this.wh = this.$win.height();
	if(this.win.innerHeight > this.win.innerWidth && this.device === "mobile") {
		this.orientation = "portait";
	} else {
		this.orientation = "landscape";
	}
 }

/**
 * Remove listeners and null out all variables.
 * @memberOf Look Around
 */

LookAround.prototype.destroy = function() {
	this.modules = _.invoke(this.modules, 'destroy') ? [] : [];
	this.el = null;
	this.$win = null;
	this.ww = null;
	this.win = null;
	this.direction = null;
	this.topOffset = null;
	this.reducer = null;
	this.device = null;
	this.x = -1;
	this.y = -1;
	app.channel('browser').off('deviceTilt', this.onDeviceTilt, this); // remove deviceTilt listener
	app.channel('browser').off('mousemove', this.onMouseMove, this); // remove mousemove listener
}

module.exports = LookAround;
