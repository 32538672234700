var $ = require("jquery");
var _ = require("lodash");
var Backbone = require("backbone");
var app = require("faction-client-site").App;
var SpriteMenu = require("./module/sprite.menu");

var win = window,
    isTouch = Modernizr.touch;

module.exports = Backbone.View.extend({
    _el: '#nav',
    el: '#nav',

    events: {
        'click a': 'navClick',
        'click #stack': 'toggleNav',
        'click .nav_title': 'openSection',
        'click #back_arrow': 'mobileBack',
        'mouseenter #stack': 'onStack',
        'mouseleave #stack': 'offStack'
    },

    initialize: function (opts) {
        //Defaults
        this.$header = $(this._el).find('#header');
        this.navOpen = false;

        app.channel('browser').on('resize', this.onResize, this); // listen for window resizing
        app.channel('browser').on('open:navLeft', this.openLeftnav, this);
        app.channel('browser').on('open:navRight', this.openRightnav, this);
        this.render();
    },

    render: function(data) {
        this.burger = new SpriteMenu('#stack');
        $('.preload').removeClass('preload');
    },

    onStack: function() {
        if(!this.navOpen) {
            this.burger.direction("forward");
        }
    },

    offStack: function() {
        if(!this.navOpen) {
            this.burger.direction("backward");
        }
    },

    openLeftnav: function () {
        var ww = $(win).width();
        this.toggleNav();
        if(ww < 680) {
          this.openSection($('#mobile_titles .nav_section')[0]);
        } else {
          this.openSection($('#nav_menu .nav_section')[0]);
        }
    },

    openRightnav: function () {
        var ww = $(win).width();
        this.toggleNav();
        if(ww < 680) {
          this.openSection($('#mobile_titles .nav_section')[1]);
        } else {
          this.openSection($('#nav_menu .nav_section')[1]);
        }
    },

    toggleNav: function(side) {

        this.navOpen = this.navOpen ? false : true;
        this.burger.navigationOpen(this.navOpen);
        if(this.navOpen) {
            this.burger.direction("forward");
        } else {
            this.burger.direction(isTouch ? "backward" :"x-burger");
        }

        if($('.nav_section').hasClass('selected')) { // if the current page link is selected close nav with a different sequence
            $('body').toggleClass('freeze');
            $('#nav .selected').toggleClass('selected');
            $('#nav .open').toggleClass('open');
            this.$header.removeClass('mobile_open');
            setTimeout(function(){
                $('#nav').toggleClass('open');
            }, 250);
        } else {
            $('body').toggleClass('freeze');
            $('#nav .selected').toggleClass('selected');
            $('#nav .open').toggleClass('open');
            $('#nav').toggleClass('open');
            this.$header.removeClass('mobile_open');
        }
    },

    navOut: function() {
        var self = this;

        this.navOpen = false;
        this.burger.navigationOpen(false);
        this.burger.direction(isTouch ? "backward" :"x-burger");

        $('#nav').addClass('out');
        self.$header.removeClass('mobile_open');
        setTimeout(function(){
            $('body').toggleClass('freeze');
            $('#nav .selected').toggleClass('selected');
            $('#nav .open').toggleClass('open');
            $('#nav').toggleClass('open');
        },400);
    },

    openSection: function(e) {
        var $section = e.type ? $(e.currentTarget).parent() : $(e),
            indx = $section.index();
            oppositeIndx = $section.index() === 1 ? 0 : 1;
            ww = $(win).width();

        if(ww < 680) {
          console.log($section.parent());
            $section.parent().toggleClass('open');
            $(this._el).find('#nav_menu .nav_section:eq('+indx+')').toggleClass('selected');
            $(this._el).find('#nav_menu .nav_section:eq('+oppositeIndx+')').toggleClass('open');
            this.$header.toggleClass('mobile_open');
        } else {
            $section.toggleClass('selected');
            $section.siblings().toggleClass('open');
        }

        return false;
    },

    mobileBack: function() {
        this.$header.toggleClass('mobile_open');
        $('#nav .selected').toggleClass('selected');
        $('#nav .open').toggleClass('open');
    },

    navClick: function (e) {
        app.channel('direction').trigger('update:direction', 'null'); // set direction to null so that the new page will not animate in from the left or right
        var href = e.currentTarget.getAttribute('href');
        if($(e.currentTarget).hasClass('current')) { // if the current page link is selected just toggle the nav closed
            this.toggleNav();
        } else {
            $('.current').removeClass('current');
            $(e.currentTarget).addClass('current');
            this.navOut();
            setTimeout(function(){
                app.router.navigate(e.currentTarget.getAttribute('href'), {
                    trigger: true
                });
            },400);
        }

        return false;
    },

    onResize: function() {
        var ww = $(win).width();
        if($(this._el).find('.nav_section.open').length) {
            if(ww < 680) {
                this.$header.addClass('mobile_open');
            } else {
                this.$header.removeClass('mobile_open');
            }
        }
    }




});
