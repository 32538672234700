/**
 ** @module Section Animation Up
 */

var app = require('faction-client-site').App,
	$ = require('jquery'),
	_ = require('lodash');

	window.App = app;

/**
 * Initializes Animations for the Sections.
 * @class
 * @param {*} el - Section Container
 */

function SectionAnimationUp (el) {
	var self = this;
    app.channel('browser').on('scroll', this.onScroll, this); // listen to scrolling on the browser channel
		this.$el = $(el);
    this.animated = false;

    this.offsetVal = $(el).data('offset') || 25;
    this.distanceLeft = 25;
		this.trigger = $(el).data('offset-trigger') || false;
}

/**
 * Get the scroll to determine the increment to slide the element.
 * @memberOf SectionAnimationUp
 */

SectionAnimationUp.prototype.onScroll = function() {
    var docViewTop = $(window).scrollTop(),
        increment = this.offsetVal - (docViewTop/28);
    this.animate(increment);

}

/**
 * Animate stats in.
 * @param {num} increment - increment for sliding up the element as the page is scrolled
 * @memberOf SectionAnimationUp
 */

SectionAnimationUp.prototype.animate = function(increment) {
		// console.log(this.distanceLeft);

		if (this.trigger) {
				var wh = $(window).height() + $(window).scrollTop(),
						elTop = this.$el.offset().top;

				if ((wh - elTop) - this.offsetVal > 0 && !this.animated) {
					this.animated = true;
					this.$el.addClass('trigger');
				}
		} else {
				if (this.distanceLeft > 0 && !this.animated) {
						this.$el.css({transform :'translateY('+increment+'%)'});
		        this.distanceLeft = increment;
		    } else {
		        this.animated = true;
		    }
		}


}

/**
 * Remove listeners and null out all variables.
 * @memberOf SectionAnimationUp
 */

SectionAnimationUp.prototype.destroy = function() {
    app.channel('browser').off('scroll', this.onScroll, this);
	this.$el = null;
    this.animated = false;
    this.offsetVal = null;
    this.distanceLeft = null;
}

module.exports = SectionAnimationUp;
